import React from 'react';
// import Footer from '../Pages/Footer';
// import {Link} from 'react-router-dom';
import Navbar from './Navbar';

export default function Devis  () {
            
    return (
    <div>
        <Navbar />
        <div className="container">
            <div className='container--devis'>

            </div>
          
        </div>
        {/* <Footer/> */}
    </div>
    );
}
